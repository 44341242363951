<template>
	<div id="exam_detail" v-loading="loading" :style="{height: height + 'px'}">
		<div class="head u-f-item u-f-jsb">
            <div>{{info.name}}</div>
        </div>
        <div class="content">
            <div class="title u-f-item u-f-jsb">
                <div>
                    共{{total}}条数据，
                    <span class="btn" @click="selectAll">选择全部</span>
                </div>
                <div class="u-f-item">
                    <el-input placeholder="请输入学生姓名、学号" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuochange">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <el-button size="small" v-if="!edit" @click="edit=true">成绩调整</el-button>
                    <el-button size="small" v-if="edit" @click="cancel">取消调整</el-button>
                    <el-button type="primary" size="small" v-if="edit" @click="save">保存</el-button>
                </div>
            </div>
            <el-table
                :data="list"
                height="87%"
                ref="multipleTable"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                style="width: 100%;border: 1px solid #EEEEEE;"
                key="2"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                <el-table-column label="学籍号" prop="student_num" align="center"></el-table-column>
                <el-table-column label="姓名" prop="realname" align="center"></el-table-column>
                <el-table-column label="班级" prop="class_name" align="center"></el-table-column>
                <el-table-column v-for="(item, i) in titleList" :key="i" :label="item['discipline_name']" align="center">
                    <template slot-scope="scope">
                        <div>
                            <blockquote style="margin: 0" v-if="scope.row[item['discipline_name']]">
                               <blockquote style="margin: 0" v-if="edit">
                                   <el-input placeholder="请输入分数" v-model="scope.row[item['discipline_name']].score" size="small"></el-input>
                               </blockquote>
                               <blockquote style="margin: 0" v-else>
                                   {{scope.row[item['discipline_name']].score}}
                               </blockquote>
                            </blockquote>
                            <blockquote style="margin: 0" v-else>{{scope.row.total_score.score}}</blockquote>
                        </div>
                    </template>
                </el-table-column>
				<el-table-column  label="总排名" align="center">
				    <template slot-scope="scope">
				        <div>
				            {{scope.row.total_score.rank}}
				        </div>
				    </template>
				</el-table-column>
            </el-table>
            <div class="u-f-item u-f-right" style="padding: 15px 0;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="limit"
                    layout="total, prev, pager, next"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	props: {
		
	},
	data() {
		return {
            height: 500,
			loading: false,
            id: '',
            info: {},
            page: 1,
            limit: 14,
            total: 0,
            list: [],
            titleList: [],
            VisibleTitle: '',
            addVisible: false,
            sousuo: '',
            ids: [],
            edit: false
		};
	},
	created() {},
	mounted() {
        this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
        this.getInfo()
	},
	methods: {
		sousuochange(){
			this.getInfo()
		},
        // 关闭弹窗
        handleClose (done) {
            this.studentList = [];
            done()
        },
        handleSelectionChange(val) {
            let arr = [];
            for(let i in val){
                arr.push(val[i].id);
            }
            this.ids = arr;
            console.log(this.ids)
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList()
        },
        handleCurrentChange1(val) {
            this.s_page = val;
            this.getStudentList()
        },
        filterTag(value, row) {
            return row.tag === value;
        },
        filterHandler(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        selectAll(){
            let self = this;
            this.list.forEach(row => {
                self.$refs.multipleTable.toggleRowSelection(row, true);
            });
        },
        // 弹窗
        VisibleTap(type,item){
            this.type = type;
            if(type==1){
                this.VisibleTitle = '添加学生';
                this.getYearList()
            }else if(type==2){
                
            }
            this.addVisible = true;
        },
        // 关闭弹窗
        closeVisible(){
            this.initialize()
            this.addVisible = false;
        },
		getInfo(){
            this.$api.student.detailexam({
                id: this.id,
            }).then(res=>{
                if (res.data.code == 1) {
                    this.info = res.data.data;
                    this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        getList(){
			let data = {
				exam_id: this.id,
				page: this.page,
				limit: this.limit
			}
			if(this.sousuo){data.keyword = this.sousuo}
            this.$api.student.detailexamscore(data).then(res=>{
                if (res.data.code == 1) {
                    let list = res.data.data.list;
                    this.total = res.data.data.total;
                    this.titleList = res.data.data.title;
                    for(let i=0;i<list.length;i++){
                        for(let j=0;j<list[i]['exam'].length;j++){
                            if(list[i]['exam'][j]['discipline_name']!=undefined){
                                let name = list[i]['exam'][j]['discipline_name'];
                                list[i][name] = list[i].exam[j]
                            }
                        }
                    }
                    this.list = list;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 选中学生
        choose(id){
            this.studentList = this.studentList.map(item=>{
                if(item.id == id&&!item.disabled){
                    item.selected = true
                }
                return item;
            })
        },
        // 添加、编辑分班
        submit(){
            if(this.type==1){
                this.addClass()
            }else if(this.type==2){
                
            }
        },
        // 添加分班
        addClass(){
            let list = this.studentList.filter(item=>item.selected==true);
            let ids = list.map(item=>{
                return item.id
            })
            this.$api.student.addCcsd({
                distribution_id: this.id,
                student_ids: ids
            }).then(res=>{
                if (res.data.code == 1) {
                    this.$message.success('添加成功');
                    this.getList();
                    this.addVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        delStu(){
            let _this = this;
            if(this.ids.length){
                this.$confirm('确定要删除？','提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(()=>{
                    this.$api.student.delCcsd({
                        id:this.ids
                    }).then(res=>{
                        if (res.data.code == 1) {
                            _this.$message.success('删除成功');
                            _this.getList();
                        } else {
                            _this.$message.error(res.data.msg);
                        }
                    })
                })
            }else{
                this.$message.error('请选择要删除的学生')
            }
        },
        cancel(){
            this.edit = false;
            this.getList();
        },
        // 保存
        save(){
            let list = [];
            for(let i=0; i<this.list.length;i++){
                for(let j=0;j<this.titleList.length;j++){
                    if(this.list[i][this.titleList[j].discipline_name]){
                        let obj = {
                            id: this.list[i][this.titleList[j].discipline_name].er_id,
                            score: this.list[i][this.titleList[j].discipline_name].score,
                        }
                        list.push(obj)
                    }
                }
            }
            this.$api.student.detailexamedit({
               details: list
            }).then(res=>{
                if (res.data.code == 1) {
                    this.$message.success('保存成功');
                    this.edit = false;
                    this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
	}
};
</script>

<style lang="scss" scope>
	#exam_detail{
       
        .head{
            background-color: #4998ff;
            padding: 15px 20px;
            div{
                color: #fff;
                font-size: 16px;
            }
        }
        .content {
            background-color: #ffffff;
            margin-top: 20px;
            padding: 0 30px;
            height: calc(100% - 100px);
            overflow-y: auto;
            .title {
                padding: 0 0 15px 0;
                .btn{
                    background-color: #f5f5f5;
                    padding: 5px;
                }
                .btn:hover{
                    color: #4998ff;
                    background: #e4f1ff;
                    cursor: pointer;
                }
            }
            .el-table th > .cell,
            .el-table .cell {
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
            }
            .el-table thead {
                background: #fafafa !important;
            }
            .el-table th {
                padding: 15px 5px !important;
                background: #fafafa !important;
            }
            .el-table::before {
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0;
            }
            .el-table .btn{
                padding: 5px;
                text-align: center;
            }
        }
        .el-dialog__body {
            padding: 20px 20px 50px;
            .con-box {
                border-top: 1px solid #eee;
                margin-top: 15px;
                max-height: 400px;
                overflow-y: auto;
                >div{
                    margin-top: 10px;
                    background-color: #F6F8FB;
                    padding: 15px;
                    >div:first-child{
                        margin-right: 30%;
                    }
                }
                >div.none{
                    color: #d8dddf;
                }
                >div.can:hover{
                    color: #4998ff;
                    background: #e4f1ff;
                    cursor: pointer;
                }
                >div.selected{
                    background-color: #4998ff;
                    color: #fff;
                }
            }
            .page{
                margin-top: 10px;
                text-align: right;
            }
        }
        .el-dialog__footer {
            padding: 15px 20px 15px;
            box-shadow: 0px -5px 10px -5px #eeeeee;
        }
    }
	
</style>
